<template>
  <div class="mainform">
    <div class="mainHeader">
      新建移库单
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form mg-form">
      <el-form class="content" ref="form" :model="form" label-width="120px">
        <el-form-item label="出库物料">
          <div class="clearfix">
            <div class="btnlaft mg-btnlaft">
              <el-button size="small" @click="showMateriel" type="primary" plain>添加物料</el-button>
            </div>
            <div class="btnlaft mg-btnlaft">
              <el-upload
                ref="upload"
                name="file"
                :auto-upload="true"
                :action= 'postUrl'
                :show-file-list="false"
                :on-exceed="handleExceed"
                :on-change="handleChansge"
                :on-success="handSuccess"
                :on-error="handError"
                :headers="tokenHeader"
              >
              <el-button size="small" type="primary" plain>上传excel</el-button>
            </el-upload>
            </div>
            <div class="downMb">
              <el-button @click="downCommonTemplate" size="small" type="primary" plain>下载模板</el-button>
            </div>
          </div>
          <div class="excel-tab">
            <el-table size=mini :data="form.bidScoringList" stripe style="width: 100%">
              <el-table-column prop="indexNum" type="index" label="序号" width="50"></el-table-column>
              <el-table-column prop="materialCode" label="物料编号" :show-overflow-tooltip="true" width="150">
                <template #default="scope" v-if="btnStarts == 1">
                  <el-input v-model="scope.row.materialCode" @blur="itCodeBlur(scope.row)" @keyup.enter="itCodeEnter(scope.row, $event)" placeholder="请输入"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="materialDesc" label="物料描述" :show-overflow-tooltip="true" width=""></el-table-column>
              <!-- <el-table-column prop="materialType" label="物料类别" :show-overflow-tooltip="true" width="100">
                <template #default="scope">
                  {{scope.row.materialType=="1"?"普通":scope.row.materialType=="2"?"专用":scope.row.materialType=="3"?"AVAP":""}}
                </template>
              </el-table-column> -->
              <el-table-column prop="units" label="单位" :show-overflow-tooltip="true" width="60"></el-table-column>
              <el-table-column prop="inventory" label="账面库存" :show-overflow-tooltip="true" width="80"></el-table-column>
              <el-table-column prop="materialNum" label="出库数量" :show-overflow-tooltip="true" width="120">
                <template #default="scope">
                  <el-input v-model="scope.row.materialNum" @blur="ckNum(scope.row)" @input="(val)=>{scope.row.materialNum = val.replace(/\D|^[0]+/g,'')}" placeholder="请输入"></el-input>
                </template>
              </el-table-column>

              <el-table-column prop="storage" label="出库库位" :show-overflow-tooltip="true" width="100"></el-table-column>

              <!-- <el-table-column prop="ruleStorage" label="出库库位" :show-overflow-tooltip="true"  width="120">
                <template  #header>
                    <el-select
                    v-model="form.ruleStorage"
                    filterable
                    remote
                    reserve-keyword
                    value-key="id"
                    placeholder="出库库位"
                    @change="handleSelect"
                    @focus="clearDataTop"
                    :remote-method="querySearch">
                      <el-option
                        v-for='item in newSelect'
                        :key='item.id'
                        :label='item.lable'
                        :value='item'>
                      </el-option>
                    </el-select>
                  </template>
                  <template #default="scope">
                    <el-select
                    v-model="scope.row.ruleStorage"
                    filterable
                    remote
                    reserve-keyword
                    value-key="id"
                    placeholder="库位"
                    @focus="clearData(scope.row)"
                    :remote-method="querySearch">
                      <el-option
                        v-for='item in newSelect'
                        :key='item.id'
                        :label='item.lable'
                        :value='item'>
                      </el-option>
                    </el-select>
                  </template>
              </el-table-column> -->

              <el-table-column prop="chuleStorage" label="移入库位" :show-overflow-tooltip="true"  width="120">
                <template  #header>
                    <el-select
                    v-model="form.chuleStorage"
                    filterable
                    remote
                    reserve-keyword
                    value-key="id"
                    placeholder="移入库位"
                    @change="inHandleSelect"
                    @focus="clearDataTop"
                    :remote-method="querySearch">
                      <el-option
                        v-for='item in newSelect'
                        :key='item.id'
                        :label='item.lable'
                        :value='item'>
                      </el-option>
                    </el-select>
                  </template>
                  <template #default="scope">
                    <el-select
                    v-model="scope.row.chuleStorage"
                    filterable
                    remote
                    reserve-keyword
                    value-key="id"
                    placeholder="库位"
                    @focus="clearData(scope.row)"
                    :remote-method="querySearch">
                      <el-option
                        v-for='item in newSelect'
                        :key='item.id'
                        :label='item.lable'
                        :value='item'>
                      </el-option>
                    </el-select>
                  </template>
              </el-table-column>

              <el-table-column label="操作" width="90">
                <template #default="scope">
                  <div v-if="btnStarts == 1">
                    <template v-if="form.bidScoringList.length === 1">
                      <el-button size="mini" type="primary" @click="addBidTeam(scope.$index)" icon="el-icon-plus" circle></el-button>
                    </template>
                    <template v-else-if="form.bidScoringList.length === scope.$index + 1">
                      <el-button size="mini" type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                      <el-button size="mini" type="primary" @click="addBidTeam(scope.$index)" icon="el-icon-plus" circle></el-button>
                    </template>
                    <template v-else>
                      <el-button size="mini" type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                    </template>
                  </div>
                  <div v-else>
                    <el-button size="mini" type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="dataBox.ruleComment" placeholder="请填写备注"></el-input>
        </el-form-item>
        <div class="action">
          <el-button class="submit" type="primary" size="medium" @click="preservation">确认出库</el-button>
        </div>
      </el-form>
    </div>
    <dialog-box ref="queryDialog" dialogWidth="78%" :dialogShow="dialogShow" @handleClose='handleClose' title="添加物料" componentName="AddMaterielMoveLibraryBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'action', type:'primary', size:'mini'}]" @action="action"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { baseURL, request, exportForm } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'AddMaterielMoveLibrary',
  props: [],
  data: function () {
    return {
      dialogShow: false,
      newSelect: [],
      newSelect1: [],
      btnStarts: 1,
      clrarList: 1,
      dataBox: {},
      menudata: this.$store.state.ViewMenuData.buttonData,
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: baseURL + '/api/order/PurchasingSystem/materialCodeOut',
      form: {
        xjDescribe: '',
        bidScoringList: [{}]
      }
    }
  },
  mounted () {
    // 绑定enter事件
    // window.addEventListener('keyup', this.itCodeEnter)
  },
  onUnmounted () {
    // 销毁enter事件
    // window.removeEventListener('keyup', this.itCodeEnter)
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  methods: {
    // 出库---表头出库库位输入下拉框选中值以后，对应整列下拉框赋值
    handleSelect (val) {
      this.form.bidScoringList.forEach(item => {
        item.ruleStorage = val
      })
    },

    // 移库---表头移入库位输入下拉框选中值以后，对应整列下拉框赋值
    inHandleSelect (val) {
      this.form.bidScoringList.forEach(item => {
        item.chuleStorage = val
      })
    },

    querySearch (queryString) {
      var str = queryString.toString()
      request('/api/livraison/entrepot/getEntrepotDataList?' + 'str=' + str, 'get').then((res) => {
        var newListEnt = []
        res.data.forEach(item => {
          newListEnt.push({
            lable: item.entrPosiName,
            id: item.id,
            storageType: item.entrPosiType,
            storageCode: item.entrPosiCode,
            receivingAddress: item.entrPosiAddr
          })
        })
        this.newSelect = newListEnt
      })
    },

    // 判断库位输入库是否有值
    clearData (row) {
      if (row.ruleStorage) {
        this.newSelect = []
      }
    },
    // 点击表头库位搜索时清空数据
    clearDataTop () {
      this.newSelect = []
    },

    // 添加一条数据
    addBidTeam (index) {
      this.form.bidScoringList.push({
        bidScoringList: []
      })
    },
    // 删除一条数据
    removeBidTeam (index) {
      this.form.bidScoringList.splice(index, 1)
    },
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (e) {
      this.$message({
        showClose: true,
        type: 'error',
        message: '只能上传一个文件'
      })
    },
    // 验证上传文件格式
    handleChansge (file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message({
          showClose: true,
          type: 'error',
          message: '上传文件只能为excel文件，且为xlsx,xls格式'
        })
        return false
      }
    },
    // 上传失败提示
    handError () {
      this.$message({
        showClose: true,
        type: 'error',
        message: '文件上传失败'
      })
    },
    // 上传成功或错误提示
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        this.btnStarts = 2
        this.clrarList = 2 // 清空列表
        // this.form.bidScoringList = response.data
        this.form.bidScoringList = []
        response.data.forEach(item => {
          this.form.bidScoringList.push({
            materialCode: item.materialCode,
            materialDesc: item.materialDesc,
            units: item.units,
            materialNum: item.materialNum,
            storage: item.storage,
            storageCode: item.storageCode,
            storageType: item.storageType,
            inventory: item.inventory
            // orderCode: item.order,
            // orderRow: item.order
          })
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: response.msg
        })
      }
    },
    // 删除一条数据
    DeleteTips (index, rows) {
      this.$confirm('确定要删除该条数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rows.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 失去焦点判断出库数量是否大于账面库存数量
    ckNum (row) {
      var numJust = true
      var ckNumber = Number(row.materialNum)
      var kckNumber = Number(row.inventory)
      if (ckNumber > kckNumber) {
        numJust = false
      }
      if (!numJust) {
        this.$message({
          showClose: true,
          message: '出库数量需小于或等于账面库存数量！',
          type: 'warning'
        })
        row.materialNum = ''
      }
    },

    // 根据物料编号请求订单信息
    queryRulNum (row) {
      const obj = {
        materialCode: row.materialCode
      }
      request('/api/order/PurchasingSystem/querySupplierMemberComponent', 'GET', obj).then((res) => {
        if (res.code === '200') {
          if (res.data.code === '200') {
            this.form.bidScoringList = res.data.data
            // this.form.bidScoringList.forEach(item => {
            //   item.ruleStorage = item.storage
            // })
          } else {
            this.$message({
              showClose: true,
              type: 'error',
              message: res.data.msg
            })
          }
        }
      })
    },
    // 失去焦点查询物料编号订单信息
    itCodeBlur (row) {
      this.queryRulNum(row)
    },
    // 物料编号录入后回车查询物料编号订单信息
    itCodeEnter (row, event) {
      this.queryRulNum(row)
      if (event) {
        event.target.blur()
      }
    },

    // 确认移库
    preservation () {
      var isHide = true
      var raHide = true
      this.form.bidScoringList.forEach(r => {
        if (!r.materialNum) {
          isHide = false
        }
      })
      // this.form.bidScoringList.forEach(r => {
      //   if (!r.ruleStorage) {
      //     isHide = false
      //   }
      // })
      this.form.bidScoringList.forEach(r => {
        if (!r.chuleStorage) {
          isHide = false
        }
      })
      if (isHide) {
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请完善出库物料信息'
        })
        return
      }

      if (!this.dataBox.ruleComment) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请填写备注信息'
        })
        return
      }

      this.form.bidScoringList.forEach(item => {
        // if (item.ruleStorage.lable) {
        //   item.storage = item.ruleStorage.lable
        //   item.storageType = item.ruleStorage.storageType
        //   item.storageCode = item.ruleStorage.storageCode
        //   item.receivingAddress = item.ruleStorage.entrPosiAddr
        // }
        if (item.chuleStorage.lable) {
          item.raStorage = item.chuleStorage.lable
          item.raStorageType = item.chuleStorage.storageType
          item.raStorageCode = item.chuleStorage.storageCode
          item.receivingAddress = item.chuleStorage.entrPosiAddr
        }
      })

      this.form.bidScoringList.forEach(s => {
        if (s.raStorage === s.storage) {
          raHide = false
        }
      })
      if (raHide) {
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '移入库位不能等于出库库位'
        })
        return
      }

      const obj = {
        inventoryMaterialDat: {
          ruleComment: this.dataBox.ruleComment,
          ruleOrdersType: '',
          ruleSupplierName: '',
          scopeOfBusiness: ''
        },
        ruleMaterialInDtos: [
          ...this.form.bidScoringList
        ]
      }
      request('/api/order/supplier/transferInsertPurchasinglier', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          this.$router.push({
            path: '/InventoryDelivery/MaterielOutLibrary'
          })
        }
      })
    },
    // 返回
    back: () => {
      window.history.back()
    },
    // 添加物料
    showMateriel () {
      this.dialogShow = true
    },
    // 点击添加物料弹框里的确定按钮
    action () {
      this.btnStarts = 2
      if (this.clrarList === 2) {
        this.form.bidScoringList = []
      }
      this.clrarList = 1
      var newListData = this.$refs.queryDialog.$refs.content.$refs.libraryData.selectData
      if (newListData.length > 0) {
        // this.form.bidScoringList = newListData
        this.dialogShow = false
        newListData.forEach(item => {
          this.form.bidScoringList.push({ ...item }) // 列表后追加数据
          item.ruleStorage = item.storage
          this.form.bidScoringList.forEach(a => {
            if (!a.materialCode) {
              this.form.bidScoringList.splice(item, 1)
            }
          })
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要入库的物料',
          type: 'warning'
        })
      }
    },
    // 下载普通物料出入库模板
    downCommonTemplate () {
      var tmUrl = '库存出库模板'
      exportForm('/api/order/PurchasingSystem/downloadMaterialExcel?title=' + tmUrl, 'post').then((res) => {
        try {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.download = '普通物料出库模板.xls'
          eleLink.target = '_blank'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        } catch (error) {
          console.error('download function error!', error)
        }
      })
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  .xj-code{
    margin-bottom: 20px;
    color: #606266;
  }
  .xj-span1{
    display: inline-block;
    width: 158px;
    text-align: right;
    padding-right: 12px;
    font-size: 14px;
  }
  .xj-span2{
    font-size: 14px;
  }
  .bidding{
    position: relative;
  }
  .bidding-ipn{
    position: absolute;
    left: 205px;
    top: -1px;
    width: 160px;
  }
  .yqgys .content{
    padding: 0 !important;
  }
  .excel-tab{
    margin-top: 10px;
  }
  .btnlaft{
    float: left;
  }
  .mg-btnlaft{
    margin-right: 10px;
  }
  .mg-form{
    margin-top: 20px;
    :deep(.el-table th){
      padding: 4px 0;
      background: #ededed;
      font-size: 14px;
    }
    :deep(.el-table td){
      font-size: 14px;
    }
    // 添加label---css
    :deep(.el-form-item__label:before) {
      content: "*";
      color: #F56C6C;
      margin-right: 4px;
    }
    // :deep(.el-input__inner){
    //   height: 30px !important;
    //   line-height: 30px !important;
    // }
  }
</style>
